<template>
    <b-modal id="modal" size="lg" :title="$t('settings.title')"
             @close="onReset()"
             no-close-on-esc
             no-close-on-backdrop
    >
        <file-manager></file-manager>
        <b-form id="form1" @reset="onReset" @submit="onSubmit" autocomplete="off">

            <b-row>
                <div class="form-group col-12">
                    <label>{{$t('settings.number_1')}}&nbsp;<b-icon icon="question-circle"
                                           v-b-tooltip.hover.html="scriptTitle" ></b-icon></label>

                    <b-input v-model="data.number_of_call_1" type="number"></b-input>
                </div>
                <div class="form-group col-12">
                    <label>{{$t('settings.number_2')}}&nbsp;<b-icon icon="question-circle"
                                           v-b-tooltip.hover.html="scriptTitle" ></b-icon></label>
        
                    <b-input v-model="data.number_of_call_2" type="number"></b-input>
                </div>

            </b-row>
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                    {{ $t('universalTxt.closeBtn') }}
                </b-button>
                <b-button type="submit" class="float-right" form="form1" variant="primary">
                    {{ $t('universalTxt.saveBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import FileManager from "../components/file-manager";
    export default {
        name: "settings",
        components: { FileManager},
        data() {
            return {
                data: {},
                scriptTitle :  { title: this.$t('settings.tooltip') },
                lang: this.$i18n.locale
            }
        },
        methods: {

            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/settings/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        params: {'id': this.id },
                        method: 'GET'
                    });

                    this.data = response.data.records;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
            async onSubmit(e) {
                e.preventDefault();
                try {

                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/settings/update-item.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: this.data,
                        method: 'PUT'
                    });

                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },

            onReset () {
                this.$router.go(-1);
            }

        },
        computed: {
            permissionsOptions: function () {
                return this.$store.getters.getUserRoles;
            }
        },
        mounted() {
            this.$bvModal.show('modal');
            this.loadData();
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">
    .tooltip-inner {
        min-width: 250px;
    }
</style>
